<template>
  <div class="contact">
    <!-- Contact us -->
    <section
      class="section novi-background bg-cover bg-white parallax-scene-js section-bottom-80 contact-top"
      id="about"
    >
      <div class="container">
        <div class="row row-50 align-items-center justify-content-md-between">
          <div class="col-md-4 col-lg-3 row-160">
            <div class="row row-40 row-md-60 margin-top-contact">
              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft">
                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon linear-icon-apartment"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_1') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <p>
                      <a
                        href="https://goo.gl/maps/fp1hKWniReZKU2i57"
                        target="_blank"
                        v-html="$t('lang.footer.card_1.description_2')"
                      ></a>
                    </p>
                    <br />
                  </div>
                </article>
              </div>
              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft" data-wow-delay=".05s">
                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon linear-icon-phone-wave"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_4') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <a href="tel:+34600207741"><p>+36 600 20 77 41</p></a>
                    <a href="tel:+34925305518"><p>+34 911 85 31 10</p></a>
                  </div>
                  <br />
                </article>
              </div>
              <div class="col-sm-4 col-md-12 col-xl-11 wow fadeInLeft" data-wow-delay=".1s">
                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon linear-icon-paper-plane"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_5') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <a href="mailto:hola@connectingtechpeople.com">hola@connectingtechpeople.com</a>
                  </div>
                  <br />
                </article>
                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon fa-tag fa-twitter"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_6') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <a href="https://twitter.com/connectingtechp ">
                      <p>https://twitter.com/connectingtechp </p>
                    </a>
                  </div>
                  <br />
                </article>

                <article class="box-icon-classic">
                  <div class="box-icon-classic-header">
                    <div class="box-icon-classic-icon novi-icon fa-tag fa-linkedin"></div>
                    <h5 class="box-icon-classic-title">{{ $t('lang.footer.card_1.description_7') }}</h5>
                  </div>
                  <div class="box-icon-classic-text">
                    <a href="https://www.linkedin.com/company/connecting-tech-people-sl/">
                      <p>https://www.linkedin.com/company/connecting-tech-people-sl/ </p>
                    </a>
                  </div>
                  <br />
                </article>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-lg-6">
            <div class="inset-xl-left-35">
              <h3 class="wow fadeInRight contact-title-margin" style="font-weight: 500">{{ $t('lang.footer.card_2.description_3') }}</h3>
              <h6
                class="title-style-1 wow fadeInRight contact-title-margin"
                data-wow-delay=".05s"
                v-html="$t('lang.footer.card_2.description_4')"
              ></h6>
              <div class="form-style-1 context-dark wow blurIn">
                <!-- RD Mailform-->
                <!-- <form action="javascript:void(0);" class="rd-mailform text-left" id="form-contact">
                  <div class="form-wrap">
                    <label class="form-label-contact" for="contact-name">{{
                      $t('lang.footer.card_2.form.name')
                    }}</label>
                    <input
                      class="form-contact"
                      id="contact-name"
                      type="text"
                      name="name"
                      data-constraints="@RequiredName"
                    />
                  </div>
                  <div class="form-wrap">
                    <label class="form-label-contact" for="contact-email">{{
                      $t('lang.footer.card_2.form.email')
                    }}</label>
                    <input
                      class="form-contact"
                      id="contact-email"
                      type="email"
                      name="email"
                      data-constraints="@Required @Email"
                    />
                  </div>
                  <div class="form-wrap">
                    <label class="form-label-contact" for="contact-phone">{{
                      $t('lang.footer.card_2.form.phone')
                    }}</label>
                    <input
                      class="form-contact"
                      id="contact-phone"
                      type="text"
                      name="phone"
                      data-constraints="@PhoneNumber"
                    />
                  </div>
                  <div class="form-wrap">
                    <label class="form-label-contact" for="contact-message">{{
                      $t('lang.footer.card_2.form.message')
                    }}</label>
                    <input
                      class="form-contact"
                      id="contact-message"
                      type="text"
                      name="message"
                      data-constraints="@RequiredMessage"
                    />
                  </div>

                  <div class="form-wrap" style="margin-top: 50px">
                    <label class="container-check">
                      <div v-html="$t('lang.footer.card_2.form.recaptcha')"></div>
                      <input type="checkbox" id="contact-check" />
                      <div id="errorCheck"></div>
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div class="form-wrap" id="captchaContactBase" style="transform: scale(0.9); left: -21px"></div>

                  <div class="form-button">
                    <button class="button button-jerry button-primary" id="emailLink" @click="gaContactForm">
                      {{ $t('lang.footer.card_2.form.btn') }}
                      <span class="button-jerry-line"></span>
                    </button>
                  </div>
                </form> -->
                <FormContact />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="layer-wrap layer-1">
        <div class="layer" data-depth="0.4">
          <img src="@/assets/images/fondo-parallax-original.webp" alt="Conectemos" width="694" height="539" />
        </div>
      </div>
    </section>

    <section>
      <div class="row">
        <div class="col-12 sub-font-size">
          <div id="map" class="mapStyle"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import FormContact from '../shared/components/form/form-contact.vue';
export default Vue.extend({
  name: 'Contact',
  metaInfo: {
    title: 'Contacto | Connecting Tech People',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          '🔥 Únete a connecting tech people🚀te ayudaremos a encontrar el proyecto que necesitas para tu éxito profesional'
      }
    ]
  },
  data: () => ({}),

    methods: {
    gaContactForm() {
      this.$ga.event({
        'eventCategory': 'form-contacto',
        'eventAction': 'submit',
        'eventLabel': '/contacto'
      });
    }
  },
  components: {
    FormContact
  }
});
</script>
